<template>
  <div>
    <div class="select-box">
      <!-- 1.筛选条件 -->
      <div>
        <span>城市:</span>
        <el-select
          v-model="option.cityId"
          clearable
          placeholder="请选择城市"
          @change="changeCity"
        >
          <el-option
            v-for="item in data.cityListData"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <span>门店:</span>
        <el-select v-model="option.shopId" clearable placeholder="请选择门店">
          <el-option
            v-for="item in data.shopListData"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <span>商品分类:</span>
        <el-select v-model="option.categoryID" placeholder="请选择商品分类">
          <el-option
            v-for="item in data.categoryListData"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div>
        <span>商品名称:</span>
        <el-input
          v-model="option.goodsName"
          clearable
          placeholder="请输入商品名称"
        ></el-input>
      </div>
      <div>
        <span>项目编码:</span>
        <el-input
          v-model="option.itemID"
          clearable
          placeholder="请输入项目编码"
        ></el-input>
      </div>
      <div>
        <el-button @click="getTabList" icon="el-icon-search" type="primary"
          >查 询</el-button
        >
      </div>
    </div>
    <!--2. table表格的内容-->
    <el-table id="table" border stripe :data="data.table" style="width: 100%">
      <el-table-column prop="id" label="ID" v-if="false"> </el-table-column>
      <!--隐藏列-->
      <el-table-column
        type="index"
        :index="indexMethod"
        width="50"
        style="text-align: center"
      >
      </el-table-column>
      <el-table-column prop="shopName" label="门店"> </el-table-column>
      <el-table-column prop="category" label="商品分类"> </el-table-column>
      <el-table-column prop="itemID" label="项目编码"> </el-table-column>
      <el-table-column prop="goodsName" label="商品名称"> </el-table-column>
      <el-table-column prop="price" label="单价"> </el-table-column>
      <el-table-column prop="lowestPrice" label="最低价"></el-table-column>
      <el-table-column prop="virtualAmount" label="虚拟销售"> </el-table-column>
      <el-table-column prop="actualAmount" label="实际销售"> </el-table-column>
      <el-table-column prop="totalAmount" label="总销售"> </el-table-column>
      <el-table-column fixed="right" label="操作" width="350">
        <template #default="scope">
          <el-button
            @click="virtualAmountClick(scope.row)"
            type="primary"
            size="small"
            >虚拟量</el-button
          >
          <el-button @click="ViewListImg(scope.row)" type="primary" size="small"
            >列表图</el-button
          >
          <el-button @click="ViewDescImg(scope.row)" type="primary" size="small"
            >详情图</el-button
          >
          <el-button
            @click="SetActiveClick(scope.row, false)"
            size="small"
            type="primary"
            v-if="scope.row.isActive"
            >下架</el-button
          >
          <el-button
            @click="SetActiveClick(scope.row, true)"
            size="small"
            type="primary"
            v-else
            >上架</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 3.分页 -->
    <el-pagination
      class="fenye"
      @current-change="handleCurrentChange"
      :current-page="option.page"
      :page-size="10"
      prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
      next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
      background
      layout="total, prev, pager, next, jumper"
      :total="data.total"
    >
    </el-pagination>
    <!-- 4.修改虚拟销售量-->
    <el-dialog
      width="32%"
      title="修改虚拟销售量"
      :show-close="true"
      v-model="ruleForm.dialogFormVisible"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleFormRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="虚拟销售量" prop="virtualAmount" required>
          <el-input
            v-model="ruleForm.virtualAmount"
            type="number"
            placeholder="数字（从小到大）"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >确 定</el-button
          >
          <!-- @click="btnSubmit"  ruleForm.dialogFormVisible = false-->
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<!-- 本页的js -->
<script>
import { reactive, ref } from "vue";
import {
  getGoodsList,
  getCategoryList,
  updateVirtualamount,
  setGoodsActive,
} from "@/http/goods";
import { getCityList, getShopList } from "../http/api";
import { useRouter } from "vue-router";

//import { ElMessage } from "element-plus";
export default {
  name: "Goods",
  setup() {
    const ruleFormRef = ref(null); //表单不能用this.$,取一个重名的
    const that = this;
    const router = useRouter();
    //声明数据
    let data = reactive({
      table: [],
      pages: 0,
      total: 0,
      shopListData: [],
      cityListData: [],
      categoryListData: [],
    });

    //声明查询条件
    let option = reactive({
      itemID: "",
      goodsName: "",
      categoryID: "",
      page: 1,
      pageSize: 10,
      cityId: "",
      shopId: "",
    });
    //城市下拉框改变时,门店下拉都要改变
    let changeCity = async (cityID) => {
      let shopList = await getShopList({ cityid: cityID });
      data.shopListData = shopList;
    };
    //获取城市列表方法
    let getCityListDataFun = async (obj) => {
      var cityList = await getCityList(obj);
      data.cityListData = cityList;
      //console.log(data.cityListData);
      //getCurrentSemesterFun();
    };
    getCityListDataFun({});
    let indexMethod = (index) => {
      return index + (option.page - 1) * option.pageSize + 1;
    };

    //修改的表单参数//////////////////////////////////////////////////
    let ruleForm = reactive({
      dialogFormVisible: false,
      id: "",
      virtualAmount: 0,
    });
    //修改的表单验证
    let rules = reactive({
      virtualAmount: [{ required: false, message: "请输入虚拟销售量" }],
    });
    let submitForm = async (formName) => {
      let con = false; //必须这样写，下面的return false是内部的方法不走了，但是下面还是执行的
      ruleFormRef.value.validate((valid) => {
        if (!valid) {
          con = true;
          //console.log("下面的不执行了");
          return false;
        }
      });
      if (con) {
        return false;
      }
      let data = {
        virtualamount: ruleForm.virtualAmount,
        goodsId: ruleForm.id,
      };
      await updateVirtualamount(data);
      await getGoodsListFun(option); //重新加载数据
      ruleForm.dialogFormVisible = false;
    };
    let resetForm = (formName) => {
      ruleFormRef.value.resetFields();
      ruleForm.dialogFormVisible = false;
    };
    let SetActiveClick = async (obj, isactive) => {
      let data = {
        active: isactive,
        goodsId: obj.id,
      };
      await setGoodsActive(data);
      await getGoodsListFun(option); //重新加载数据
    };
    let ViewListImg = (obj) => {
      router.push({ name: "GoodsImg", query: { id: obj.id } });
      //router.push({ path: `/GoodsImg/${obj.id}` });
    };
    let ViewDescImg = (obj) => {
      router.push({ name: "GoodsDescImg", query: { id: obj.id } });
      //router.push({ path: `/GoodsDescImg/${obj.id}` });
    };
    let virtualAmountClick = async (obj) => {
      ruleForm.id = obj.id;
      //赋值默认值弹框获取ID号，方便下一步进行新增及修改等
      ruleForm.virtualAmount = obj.virtualAmount;
      ruleForm.dialogFormVisible = true; //显示出表单
    };
    let getCategoryListDataFun = async (obj) => {
      let categoryList = await getCategoryList(obj);
      data.categoryListData = categoryList;
    };
    //获取订单列表方法
    let getGoodsListFun = async (obj) => {
      let arr = await getGoodsList(obj);
      data.total = arr.totalCount;
      data.table = arr.items;
    };

    //初始化时，获取初始化数据
    getGoodsListFun(option);
    getCategoryListDataFun({});
    //点击页码获取数据
    let handleCurrentChange = (index) => {
      option.page = index;
      getGoodsListFun(option);
    };
    /** 图片查看 */
    let getImgList = (workPhoto, index) => {
      let arr = [];
      if (workPhoto.length == 1) {
        arr.push(workPhoto[0]);
      } else if (workPhoto.length == 0) {
        return arr;
      } else {
        for (let i = 0; i < workPhoto.length; i++) {
          arr.push(workPhoto[i + index]);
          if (i + index >= workPhoto.length - 1) {
            index = 0 - (i + 1);
          }
        }
      }
      return arr;
    };
    //查询方法,此处需要根据条件到后台进行筛选
    let getTabList = async () => {
      option.page = 1;
      getGoodsListFun(option);
    };

    return {
      data,
      handleCurrentChange,
      getTabList,
      option,
      getImgList,
      virtualAmountClick,
      SetActiveClick,
      resetForm,
      submitForm,
      ViewListImg,
      ViewDescImg,
      rules,
      ruleForm,
      ruleFormRef,
      indexMethod,
      changeCity,
      getCityListDataFun,
    };
  },
};
</script>

<!-- 本页的css -->
<style lang="scss" scoped>
.select-box {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 25px;
  > div {
    width: 14%;
    display: flex;
    align-items: center;
    span {
      width: 120px;
    }
    margin-right: 20px;
  }
}
.fenye {
  margin-top: 20px;
}
</style>
